import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'

import AvatarCard from '../../components/AvatarCard/AvatarCard'
import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

const WhoWeAre = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        {data.markdownRemark.frontmatter.staff.length ? (
          <>
            <h2>Staff</h2>
            <div className="flex-row justify-space-around" style={{ flexWrap: 'wrap' }}>
              {data.markdownRemark.frontmatter.staff.map(member => <AvatarCard member={member} key={member.name} />)}
            </div>
          </>
        ) : null}
        {data.markdownRemark.frontmatter.board.length ? (
          <>
            <h2>Board</h2>
            <p className="center-text"><ReactMarkdown children={data.markdownRemark.frontmatter.boardInfo} /></p>
            <div className="center-text board-members">
              {data.markdownRemark.frontmatter.board.map(member => <AvatarCard member={member} key={member.name} />)}
            </div>
          </>
        ) : null}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query WhoWeAreQuery {
  markdownRemark(frontmatter: {title: {eq: "Who We Are"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      staff {
        name
        position
        pronouns
        email
        phone
        # photo { #commenting out until they need it again because gatsby-image is throwing errors
        #   childImageSharp {
        #     gatsbyImageData(
        #       layout: CONSTRAINED
        #       aspectRatio: 1
        #       transformOptions: {fit: INSIDE, cropFocus: ATTENTION, trim: 10}
        #     )
        #   }
        # }
      }
      board {
        name
        position
      }
      boardInfo
    }
  }
}
`

export default WhoWeAre