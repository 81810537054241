import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import moment from 'moment'

import * as styles from './AvatarCard.module.css'

import { formatPhoneNumber } from '../../utilities/formatPhoneNumber'

const AvatarCard = (props) => {
  const image = props.member.photo ? getImage(props.member.photo) : null

  return (
    <div className={styles.avatar_card}>
      {props.member.photo ? <GatsbyImage image={image} alt={props.member.name} /> : null}
      <h3>{props.senator ? 'Sen. ' : null}{props.representative ? 'Rep. ' : null}{props.member.name}</h3>
      {props.member.position ? <span>{props.member.position || props.position || props.member.district}</span> : null}
      {props.position ? <span>{props.position}</span> : null}
      {props.member.district ? <span>District {props.member.district}</span> : null}
      {props.member.dateInOffice ? <span>Since {moment(props.member.dateInOffice).format('MMMM DD, YYYY')}</span> : null}
      <span>{props.member.pronouns}</span>
      <small>{props.member.party}</small>
      <a href={`mailto:${props.member.email}`}>{props.member.email}</a>
      {props.member.phone ? <a href={`tel:+1${props.member.phone}`}>{formatPhoneNumber(props.member.phone.toString())}</a> : null}
    </div>
  )
}

export default AvatarCard